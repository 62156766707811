<template>
    <div>
      <div class="back" v-if="show">
        <div style="text-align: center;margin-top: 2rem;">
            <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/powerRate/success.png" alt="" style="width: 3rem;height: 3rem;">
            <div style="font-size: .6rem;margin-top: .3rem;">
                支付成功
            </div>
            <div style="margin-top: 1rem;display: flex;justify-content: center;">
                <div class="downLoad">
                <div class="downLoad_a" @click="dowond_a">
                    <img src="../assets/img/Android_d.png" alt="">
                    <div>Android下载</div>
                </div>
                <div class="downLoad_i" @click="dowond_i">
                    <img src="../assets/img/iPhone_d.png" alt="">
                    <div>iPhone下载</div>
                </div>
            </div>
            </div>
        </div>
      </div>
      <div v-else style="height: 100vh;display: flex;flex-direction: column;justify-content: center;">
          <div>
              <van-loading size="36px" type="spinner" color="rgb(0, 140, 255)" vertical>
                  <div>订单加载中...</div>
                  <div>
                      
                  </div>
              </van-loading>
          
              <div  style="width: 80%; margin: auto;margin-top: 10px;border: 1px solid #ccc;text-align: center;padding: 10px 0;border-radius: 20px;" @click="openBack">
                  不等了(返回上一页{{ 4-num<=0?'':4-num }})
              </div>
          </div>
          
  
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { showToast, showLoadingToast,closeToast } from "vant";
  export default {
    data() {
      return {
        show: false,
        order_id:'',
        channel_id:'',
        pay_type:'',
        pay_price:'',
        callback_url:'',
        num:0,
        
      };
    },
    
    mounted() {
      const { order_id, channel_id, channel_no, pay_type, pay_price, callback_url,} = this.$route.query
              this.order_id = order_id
              this.channel_id = channel_id
              this.pay_price = pay_price
              this.pay_type = pay_type
              this.callback_url = callback_url
              if(this.order_id) {
                  this.loopOrderState({
                              order_id: this.order_id,
                              channel_id: this.channel_id
                          })
  
              }
    },
    methods: {
        dowond_a() {
                window.open('https://wwh-web.oss-cn-beijing.aliyuncs.com/test_apps/2023-08-17/ea12232dbbe4db99e63f4934ba54a3a8.apk')
            },
            dowond_i() {
                window.open('https://apps.apple.com/cn/app/id6462085509')

            }, 
      openBack(){
          if(4-this.num<=0){
              this.callback();
          }
      },
      getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
          return unescape(r[2]);
        }
  
        return null;
      },
      loopOrderState(datas) {
        //轮询当前订单状态s
        let that = this;
        that. num = 0;
        let data = datas
        
        that.T = setInterval(() => {
          axios
            .post(`${window.location.origin}/api/v1/electric/couponQueryOrder`, data)
            .then((res) => {
              if (res.data.code == 0) {
                clearInterval(that.T);
                that.show=true
              } else {
                  that.num++;
                if (that.num > 9) {
                  that.callback();
                }
              }
            })
            .catch(() => {
              clearInterval(that.T);
              showToast("网络错误，请稍后重试");
              this.showsuccess_pay = false;
            });
        }, 1000);
      },
      callback() {
        clearInterval(this.T);
        showToast("订单未支付");
        setTimeout(() => {
          window.location.href = decodeURIComponent(atob(this.callback_url));
        }, 500);
      },
      advGo() {
        window.location.href = "https://u.tuiabcedc.cn/2c5a";
      },
      goWxKefu() {
        window.location.href =
          "https://work.weixin.qq.com/kfid/kfc1fbe95a001b15c7d";
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  @keyframes subumitScale {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(.86);
    }
    100%{
        transform: scale(1);
    }
  }
  .back {
    width: 100%;
    height: 100vh;
    .backImg {
      width: 100%;
    }
    .adv {
      position: absolute;
      top: 13.68rem;
      text-align: center;
      animation: subumitScale 1s linear alternate infinite;
      // z-index: 10;
      .widHig {
        width: 8.2rem;
      }
    }
    .succ {
      position: absolute;
      top: 16.2rem;
      text-align: center;
      // z-index: 10;
      .widHigSucc {
        width: 8.2rem;
      }
    }
  }
  .downLoad{
            >div{
                width: 4.53rem;
                height: 1.17rem;
                border: 1px solid #1B1B1B;
                border-radius: 0.59rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.31rem 0.77rem;
                box-sizing: border-box;
                font-size: 0.37rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #022222;

            }
            
            .downLoad_i{
                margin-top: .3rem;
            }

        }
  </style>