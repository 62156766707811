<template>
  <div>
    <div class="back" v-if="show">
      <div style="position: relative; width: 100%; height: auto">
        <div style="position: relative">
          <img
            src="../assets/img/backSuccess.png"
            alt=""
            class="backImg"
            style="pointer-events: none"
          />
          <!-- <div style="position: absolute;right: 0;top: 1rem;width: .56rem;background: #e7bd52;text-align: center;border-radius: 5px 0 0 5px;color: #fff;" @click="tuikuan">
            自助退款
        </div> -->
        </div>

        <div style="width: 100%" class="succ" @click="goWxKefu">
          <img
            src="../assets/img/successBtn.png"
            alt=""
            class="widHigSucc"
            style="pointer-events: none"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      style="
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div>
        <div style="text-align: center">
          <img
            src="../assets/img/order_detail.jpg"
            alt=""
            style="width: 3rem"
          />
        </div>
        <van-loading size="36px" type="spinner" color="rgb(0, 140, 255)" vertical>
          <div>订单加载中...</div>
          <div></div>
        </van-loading>

        <div
          style="
            width: 80%;
            margin: auto;
            margin-top: 10px;
            text-align: center;
            padding: 15px 0;
            border-radius: 30px;
            font-size: 0.3rem;
            background-color: #4e51fe;
            color: #fff;
            font-weight: bold;
          "
          @click="openBack"
        >
          不等了(返回上一页{{ 4 - num <= 0 ? "" : 4 - num }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { showToast, showLoadingToast, closeToast } from "vant";
export default {
  data() {
    return {
      show: false,
      order_id: "",
      channel_id: "",
      pay_type: "",
      pay_price: "",
      callback_url: "",
      num: 0,
    };
  },

  mounted() {
    const {
      order_id,
      channel_id,
      channel_no,
      pay_type,
      pay_price,
      callback_url,
    } = this.$route.query;
    this.order_id = order_id;
    this.channel_id = channel_id;
    this.pay_price = pay_price;
    this.pay_type = pay_type;
    this.callback_url = callback_url;
    if (this.order_id) {
      this.loopOrderState({
        order_id: this.order_id,
        channel_id: this.channel_id,
      });
    }
  },
  methods: {
    openBack() {
      if (4 - this.num <= 0) {
        this.callback();
      }
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }

      return null;
    },
    loopOrderState(datas) {
      //轮询当前订单状态s
      let that = this;
      that.num = 0;
      let data = datas;

      that.T = setInterval(() => {
        that.num++;
        if (that.num > 9) {
          that.callback();
        }
        axios
          .post(`${window.location.origin}/api/v1/video/payOrderSuccess`, data)
          .then((res) => {
            if (res.data.code == 0) {
              clearInterval(that.T);
              that.show = true;
            }
          })
          .catch(() => {
            clearInterval(that.T);
            that.num = 5;
            showToast("网络错误，请稍后重试");
            this.showsuccess_pay = false;
          });
      }, 1000);
    },
    callback() {
      clearInterval(this.T);
      showToast("订单未支付");
      setTimeout(() => {
        window.location.href = decodeURIComponent(atob(this.callback_url));
      }, 500);
    },
    advGo() {
      window.location.href = "https://u.tuiabcedc.cn/2c5a";
    },
    goWxKefu() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc017f5c011f5d0100";
      // axios
      //   .post(`${window.location.origin}/api/v1/wechat/auth/generate/scheme2`, {
      //     path: "/pages/vedioList/vedioList",
      //     query: "orderId=" + this.order_id,
      //   })
      //   .then((url) => {
      //     console.log(url);
      //     if (url.data.code == 0) {
      //       let mockUrlScheme = "";
      //       var m = url.data.data.url_link.match(/wxaurl\.cn\/(\w+)/);
      //       if (m && m[1]) {
      //         mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
      //       }
      //       window.location.href = mockUrlScheme;
      //     }
      //   });
    },
    tuikuan() {
      window.location.href =
        "https://video-h5.baishiwang.xyz/?type=1#/pages/tuikuan/tuikuan";
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes subumitScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.86);
  }
  100% {
    transform: scale(1);
  }
}
.back {
  width: 100%;
  height: 100vh;
  background: red;
  .backImg {
    width: 100%;
  }
  .adv {
    position: absolute;
    top: 13.68rem;
    text-align: center;
    animation: subumitScale 1s linear alternate infinite;
    // z-index: 10;
    .widHig {
      width: 8.2rem;
    }
  }
  .succ {
    position: absolute;
    // top: 16.2rem;
    top: 13.68rem;
    text-align: center;
    // z-index: 10;
    .widHigSucc {
      width: 8.2rem;
    }
  }
}
</style>
