import { createStore } from 'vuex'
import testApi from '@/api/test';
/**
 * 创建仓库和导出
 */
export default createStore({
  state: {

  },
  mutations: {

  },
  actions: {
 
  }
})
