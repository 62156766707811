import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Vant from 'vant'
import 'lib-flexible'
import 'vant/lib/index.css'
import mixins from './mixin' // 全局 mixins
const app = createApp(App)

app.use(store)
app.use(router)
app.use(Vant)
app.mixin(mixins)
app.mount('#app')
